import React, { FC } from "react";
import moment from "moment";
import Link from "next/link";

// components
import Logo from "@components/layout/Logo";
import BackButton from "@components/common/BackButton";
import BlueButton from "@components/common/BlueButton";

interface Props {
  data?: any;
}

const ReportCard: FC<Props> = ({ data }) => {
  return (
    <div className="cursor-pointer h-48 rounded-md hover:border-blue-500 border-2 p-5">
      <div className="flex-none">
        <div className="grid grid-flow-row-dense lg:grid-cols-5">
          <div className="col-span-1 lg:col-span-2 relative max-w-xs overflow-hidden bg-cover rounded">
            <div className="absolute border border-black border-opacity-10 rounded-md h-full w-full hover:bg-black/[.10] transition duration-100 ease-in-out"></div>
            <img
              src={`${process.env.BASE_MEDIA_URL}${data?.attributes?.coverImage?.data?.attributes?.url}`}
              alt="Just a flower"
              className="w-full rounded object-cover h-[140px]"
            />
          </div>
          <div className="col-span-1 lg:col-span-3 p-2">
            <div className="flex-auto ml-1 justify-evenly">
              <div className="flex flex-col">
                <div className="w-full text-ellipsis text-xs line-clamp-2 font-medium text-black/[.80] mb-2">
                  {data.attributes.title}
                </div>
                <h2 className="flex-auto text-xs line-clamp-3 overflow-hidden text-ellipsis font-regular text-black/[.50]">
                  {data.attributes.description}
                </h2>
                <h2 className="flex-auto text-xs font-regular text-black/[.50] mt-1 mb-1">
                  {moment(data?.attributes.createdAt).format("YYYY/MM/DD")}
                </h2>
                <h2 className="mt-2 text-xs font-medium text-blue-500">
                  Дэлгэрэнгүй
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ReportCard.defaultProps = {
  data: {
    attributes: {
      title: "Л.Энх-Амгалан",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus bibendum erat et tellus gravida, ut bibendum lacus mattis. Phasellus quis rutrum dolor. Sed dignissim accumsan dolor eleifend consequat.",
      image: {},
    },
  },
};

export default ReportCard;
