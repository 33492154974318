import React, { useState, useRef } from "react";
import Link from "next/link";
import Carousel, { consts } from "react-elastic-carousel";
import ReportCard from "@components/pages/ReportCard";
import EventCard from "@components/pages/EventCard";
import ContenWrapper from "./style";

const breakPoints = [
  { width: 1, itemsToShow: 1, itemsToScroll: 1 },
  { width: 550, itemsToShow: 1, itemsToScroll: 1 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 5 },
];

const ReportSlider = ({ datas, title, comp = "simple" }) => {
  const carouselRef = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);

  const movePrev = () => {
    if (currentIndex === 0) return;
    carouselRef.current.goTo(currentIndex - 1);
    setCurrentIndex((prev) => prev - 1);
    // carouselRef.current.slidePrev();
  };

  const moveNext = () => {
    if (currentIndex === datas.data.length - 1) return;
    carouselRef.current.goTo(currentIndex + 1);
    setCurrentIndex((prev) => prev + 1);
    // carouselRef.current.slideNext();
  };

  const goto = ({ target }) => {
    carouselRef.goTo(Number(target.value));
  };

  return (
    <div className="">
      <div className="flex justify-between items-center mb-2">
        <div className="text-sm font-medium">{title}</div>
        <div className="flex flex-nowrap">
          <div>
            <button
              onClick={movePrev}
              className="disabled:opacity-25 disabled:cursor-not-allowed z-10 p-0 m-0 transition-all border ease-in-out duration-300  rounded-full flex justify-center items-center p-1 w-[30px] h-[30px] hover:bg-gray-100"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M15.0001 19.92L8.48009 13.4C7.71009 12.63 7.71009 11.37 8.48009 10.6L15.0001 4.08"
                  stroke="#c4c4c4"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
          <div className="ml-2">
            <button
              onClick={moveNext}
              className="disabled:cursor-not-allowed z-10 m-0 transition-all ease-in-out duration-1300 border rounded-full flex justify-center items-center p-1 w-[30px] h-[30px] hover:bg-gray-100"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M8.90991 19.92L15.4299 13.4C16.1999 12.63 16.1999 11.37 15.4299 10.6L8.90991 4.08"
                  stroke="#c4c4c4"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <ContenWrapper>
        <div>
          <Carousel
            ref={carouselRef}
            pagination={false}
            showArrows={false}
            itemPosition={consts.START}
            breakPoints={breakPoints}
            itemPadding={[0, 5, 0, 0]}
          >
            {datas.data.map((data, index) => {
              return (
                <div className="w-full">
                  <a
                    target="_blank"
                    href={`${process.env.BASE_MEDIA_URL}${data?.attributes?.file?.data?.attributes?.url}`}
                  >
                    {comp === "simple" ? (
                      <ReportCard data={data} />
                    ) : (
                      <EventCard data={data} />
                    )}
                  </a>
                </div>
              );
            })}
          </Carousel>
        </div>
      </ContenWrapper>
    </div>
  );
};

export default ReportSlider;
