import React, { FC } from "react";
import moment from "moment";
import Link from "next/link";

interface Props {
  data?: any;
}

const NewsBox: FC<Props> = ({ data }) => {
  return (
    <div className="relative cursor-pointer rounded-lg">
      <div className="overflow-hidden rounded">
        <figure className="border rounded border-black border-opacity-10 overflow-hidden relative max-w-sm transition-all duration-300 cursor-pointer">
          <img
            // src={`${process.env.BASE_MEDIA_URL}${data.attributes.image?.data?.attributes?.url}`}
            src={`${process.env.BASE_MEDIA_URL}${data.attributes.image?.data?.attributes?.formats.small.url}`}
            alt="Just a flower"
            className="h-36 w-full rounded transition duration-500 ease-in-out hover:scale-105 object-cover"
          />
        </figure>
      </div>
      <p className="mt-1 line-clamp-3 overflow-hidden text-xs tracking-normal leading-4 font-medium text-black/[.60] hover:text-black/[.80]">
        {data.attributes.title}
      </p>
    </div>
  );
};

NewsBox.defaultProps = {
  data: {
    attributes: {
      title:
        "Боловсрол Шинжлэх Ухааны сайд Л.Энх-Амгалан Австралийн Боловсролын сайд Жейсон Клэйртэй албан ёсны уулзалт хийлээ.",
      description:
        "хэрэглэгчийн эрх үүсгэн нэвтэрч, цахим өргөдлийг бөглөх, илгээх гарын авлагатай танилцан, өргөдлийг бөглөж илгээнэ. Өргөдөлд дурдсан дараах баримт бичиг тус бүрийг PDF /1 файлын хэмжээ 5Mb-аас ихгүй байх/ хэлбэрээр “баримт бичиг” хэсэгт хавсаргана",
      image: {},
    },
  },
};

export default NewsBox;
