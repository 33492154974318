import React, { FC } from "react";

interface Props {
  data?: any;
}

const NewsCard: FC<Props> = ({ data }) => {
  console.log(data);
  return (
    <div className="h-full relative z-30 items-center justify-center rounded-md overflow-hidden cursor-pointer rounded-md">
      {/* <div className="border border-black border-opacity-10 rounded-md h-full w-full"></div> */}
      <div className="">
        <img
          src={`${process.env.BASE_MEDIA_URL}${data?.attributes?.image?.data.attributes.formats?.large?.url}`}
          // alt="Just a flower"
          // className=""
          className="object-cover w-full rounded transition duration-300 ease-in-out hover:scale-105"
        />
      </div>
      <div className="absolute inset-x-0 bottom-0 z-20 flex items-center justify-center w-full backdrop-blur-sm bg-black/60">
        <div className="text-white m-4 pl-4 pr-10 line-clamp-2 overflow-hidden hover:text-blue-300">
          {data.attributes.title}
        </div>
      </div>
    </div>
  );
};

NewsCard.defaultProps = {
  data: {
    attributes: {
      title:
        "Боловсрол Шинжлэх Ухааны сайд Л.Энх-Амгалан Австралийн Боловсролын сайд Жейсон Клэйртэй албан ёсны уулзалт хийлээ.",
      short_description: "Баасансүрэн",
      image: {},
    },
  },
};

export default NewsCard;
