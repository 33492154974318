import styled from "styled-components";

const ContentWrapper = styled.div`
  .rec-slider-container {
    margin: 0 !important;
  }
  .seperator {
    width: 100%;
  }

  .controls-wrapper {
    width: 100%;
  }

  .carousel-wrapper {
    width: 100%;
  }

  .rec-carousel-item:focus {
    outline: none;
    box-shadow: inset 0 0 1px 0px violet;
  }
  .rec-slider-container {
    margin: 0px !important;
    border-color: red;
  }
  .hHJTFD {
    border-color: red;
    margin: 0px !important;
  }
`;

export default ContentWrapper;
