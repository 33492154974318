import React, { FC, useState } from "react";
import FlipPage from "@components/common/FlipPage";

interface Props {
  data?: any;
  onClick?: Function;
}

const NewsPaper: FC<Props> = ({ data, onClick }) => {
  const [isOpen, setOpen] = useState(false);

  const handleClickPaper = () => {
    setOpen(true);
  };

  const handleCloseButton = () => {
    setOpen(false);
  };

  return (
    <>
      <div onClick={handleClickPaper}>
        <div className="w-full z-30 relative items-center justify-center  overflow-auto rounded-md cursor-pointer rounded-md">
          <div className="absolute h-full border border-black border-opacity-10 bg-black/[.05] rounded-md w-full hover:bg-black/[.20] transition duration-100 ease-in-out"></div>

          <div className="h-[220px]">
            <img
              src={`${process.env.BASE_MEDIA_URL}${data?.attributes?.coverImage?.data.attributes.url}`}
              alt="Just a flower"
              className="rounded h-full w-full object-contain object-top"
            />
          </div>
          <div className="inset-x-0 bottom-0 z-20 flex items-center justify-center w-full bg-white"></div>
        </div>
        <div className=" text-xs text-gray-500 px-2 pt-2 line-clamp-2">
          {data.attributes.title}
        </div>
      </div>
      {isOpen ? (
        <FlipPage
          fileUrl={`${process.env.BASE_MEDIA_URL}${data.attributes.pdfFile.data.attributes.url}`}
          onClose={handleCloseButton}
        />
      ) : (
        ""
      )}
    </>
  );
};

NewsPaper.defaultProps = {
  data: {
    attributes: {
      date: "2023.02",
      title: "Ирээдүйн боловсролыг дахин төлөвлөх,",
      short_description: "Баасансүрэн",
      image: {},
    },
  },
};

export default NewsPaper;
