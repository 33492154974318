import React, { FC, useState } from "react";
import FsLightbox from "fslightbox-react";

interface Props {
  data?: any;
  onClick?: Function;
}

const NewsPaper: FC<Props> = ({ data, onClick }) => {
  const [toggler, setToggler] = useState(false);

  const images = [];
  if (data?.attributes?.files.data.length) {
    for (let i = 0; i < data?.attributes?.files.data.length; i++) {
      images.push(
        `${process.env.BASE_MEDIA_URL}${data?.attributes?.files.data[i]?.attributes.url}`
      );
    }
  }

  return (
    <>
      <div className="" onClick={() => setToggler(!toggler)}>
        <div className="w-full z-30 relative items-center justify-center overflow-auto rounded-md cursor-pointer rounded-md">
          <div className="absolute h-full border border-black border-opacity-10 rounded-md w-full hover:bg-black/[.20] transition duration-100 ease-in-out"></div>

          <div className="h-[220px]">
            <img
              src={`${process.env.BASE_MEDIA_URL}${data?.attributes?.files.data[0].attributes.url}`}
              alt="Just a flower"
              className="rounded h-full w-full object-cover object-top"
            />
          </div>
          <div className="inset-x-0 bottom-0 z-20 flex items-center justify-center w-full bg-white"></div>
        </div>
        <div className=" text-xs text-gray-500 px-2 pt-2 line-clamp-3">
          {data.attributes.title}
        </div>
      </div>
      <FsLightbox
        toggler={toggler}
        sources={images}
        thumbs={images}
        showThumbsOnMount={true}
      />
    </>
  );
};

NewsPaper.defaultProps = {
  data: {
    attributes: {
      title: "БСҮХ-ийн сар тутмын хэвлэл XII сар",
      short_description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus bibendum erat et tellus gravida, ut bibendum lacus mattis. Phasellus quis rutrum dolor. Sed dignissim accumsan dolor eleifend consequat.",
      image: {},
    },
  },
};

export default NewsPaper;
