import React, { useState } from "react";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

// components
import MainLayout from "@components/layout/MainLayout";
import NewsBox from "@components/pages/NewsBox";
import NewsCard from "@components/pages/NewsCard";
import ReportSlider from "@components/pages/ReportSlider";
import NewsPaper from "@components/pages/NewsPaper";
import InfoGraphic from "@components/pages/InfoGraphic";

// services
import NewsService from "@services/news";
import MeasureService from "@services/measure";
import ResearchService from "@services/research";
import MagazineService from "@services/magazine";
import InfoGraphicService from "@services/infographic";

const Index = ({ news, measure, reports, magazines, infographics }) => {
  const { t } = useTranslation("common");

  return (
    <MainLayout>
      <div className="w-full">
        <div className="grid lg:grid-cols-2 gap-5 mt-6 mb-10 lg:gap-y-2">
          <div className="text-sm font-medium col-span-2">{t("newstitle")}</div>
          <div className="col-span-2 lg:col-span-1">
            {news && (
              <Link href={`news/${news.data[0].id}`}>
                <a className="">
                  <div className="h-48 lg:h-[410px]">
                    <NewsCard data={news.data[0]} />
                  </div>
                </a>
              </Link>
            )}
          </div>
          <div className="grid col-span-2 lg:col-span-1 grid-cols-2 gap-4">
            {news.data.map((item, ind) => {
              if (ind === 0) return;
              return (
                <div className="w-full">
                  <Link href={`news/${item.id}`}>
                    <a>
                      <NewsBox data={item} />
                    </a>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="w-full mt-6 mb-10">
        <div className="grid lg:grid-cols-2 gap-4">
          <ReportSlider datas={reports} title={t("researchtitle")} />
          <ReportSlider datas={measure} title={t("hometitle")} comp="news" />
        </div>
      </div>
      <div className="w-full">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6 mb-10">
          <div className="flex flex-col">
            <div className="flex text-sm font-medium justify-between items-center">
              <div>{t("magazinetitle")}</div>
              <div className="cursor-pointer text-xs text-black/[.40] hover:text-blue-500 flex items-center">
                <Link href="/products/paper">
                  <a>Бусад</a>
                </Link>
              </div>
            </div>
            <div className="grid grid-cols-2 lg:grid-cols-3 gap-5 rounded-lg mt-3">
              {magazines.data.map((item) => {
                return <NewsPaper data={item} />;
              })}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex text-sm font-medium justify-between items-center">
              <div>{t("infographictitle")}</div>
              <div className="cursor-pointer text-xs text-black/[.40] hover:text-blue-500">
                <Link href="/products/infographic">
                  <a>Бусад</a>
                </Link>
              </div>
            </div>
            <div className="grid grid-cols-2 lg:grid-cols-3 gap-5 rounded-lg h-full mt-3">
              {infographics.data.map((item) => {
                return <InfoGraphic data={item} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export async function getServerSideProps({ req, res, locale }) {
  const news = await NewsService.getList(
    "&pagination[page]=1&pagination[pageSize]=5&filters[post_type][type][$contains]=news&filters[is_home][$eq]=1&sort[0]=createdAt%3Adesc"
  );
  const measure = await MeasureService.getList(
    "&pagination[page]=1&pagination[pageSize]=3&filters[post_type][type][$contains]=measure&sort[0]=createdAt%3Adesc"
  );
  const magazines = await MagazineService.getList(
    "&pagination[page]=1&pagination[pageSize]=6&sort[0]=createdAt%3Adesc"
  );
  const infographics = await InfoGraphicService.getList(
    "&pagination[page]=1&pagination[pageSize]=6&sort[0]=createdAt%3Adesc"
  );
  const reports = await ResearchService.getResearchReports(
    "&pagination[page]=1&pagination[pageSize]=6&sort[0]=createdAt%3Adesc"
  );

  return {
    props: {
      ...(await serverSideTranslations(locale ?? "mn", ["common", "layout"])),
      news,
      measure,
      reports,
      magazines,
      // partners,
      infographics,
    },
  };
}

export default Index;
