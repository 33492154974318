import React, { useState, useEffect, useRef } from "react";
import HTMLFlipBook from "react-pageflip";
// import { MobilePDFReader } from "react-pdf-viewer";
import { Document, pdfjs, Page as ReactPdfPage } from "react-pdf";

import BlueButton from "@components/common/BlueButton";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// const width = 180;
// const height = 860;

const Page = React.forwardRef(({ pageNumber, width }, ref) => {
  return (
    <div ref={ref}>
      <ReactPdfPage pageNumber={pageNumber} width={width} />
    </div>
  );
});

const CustomFlipPage = (props) => {
  const flipPageRef = useRef(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setLoading] = useState(true);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onDocumentLoadSuccess = async ({ numPages }) => {
    setNumPages(numPages);
    setLoading(false);
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width: width < 600 ? width : 630,
      height,
    };
  }

  return (
    <div className="w-full h-screen flex justify-center items-center fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-full h-screen bg-gray-700 flex flex-col justify-center items-center">
        <div className="w-full h-[900px] overflow-hidden flex flex-row justify-center">
          <div className="w-full lg:w-[1260px]">
            <Document
              file={props.fileUrl}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <HTMLFlipBook
                size="fixed"
                ref={flipPageRef}
                getCurrentPageIndex={4}
                flippingTime={500}
                width={windowDimensions.width}
                height={900}
              >
                {[...Array(numPages)].map((x, i) => (
                  <Page pageNumber={i + 1} width={windowDimensions.width} />
                ))}
              </HTMLFlipBook>
            </Document>
          </div>
        </div>
        <div className="absolute bottom-2 flex flex-row justify-center">
          <BlueButton
            name={`Өмнөх`}
            onClick={() => flipPageRef.current.pageFlip().flipPrev()}
          />
          <div className="px-1"></div>
          <BlueButton
            name={`Дараах`}
            onClick={() => flipPageRef.current.pageFlip().flipNext()}
          />
          <div className="px-1"></div>
          <BlueButton name={`Хаах`} onClick={props.onClose} />
        </div>
      </div>
    </div>
  );
};

export default CustomFlipPage;
