import React, { FC } from 'react';

interface Props {
  name?: string;
  onClick?: Function;
}

const BlueButton: FC<Props> = ({ name, onClick }) => {
  const handleClick = async values => {
    onClick();
  };

  return (
    <button
      onClick={handleClick}
      className="mt-2 w-max text-white bg-blue-600 hover:bg-blue-700 focus:outline-none rounded-full px-4 py-1.5 text-xs text-center inline-flex items-center justify-center"
    >
      {name}
    </button>
  );
};

BlueButton.defaultProps = {
  name: 'my button',
  onClick: () => {
    console.log('');
  },
};

export default BlueButton;
